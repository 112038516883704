import axios from "axios";

import { cookieStorage } from "../utils/cookie";
import { API_URL, CAL_API_URL } from "../utils/environment";

import { calComUserSuccess, loadingSuccess, setConnectedCalendarsSuccess } from "../store/slices/calcom";
import { dispatch } from "../store";

const axiosServices = axios.create({ baseURL: API_URL + "/api/v1" });

export const getCalUserService = (id) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.get(`/cal/${id}`, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(calComUserSuccess({ user: response.data }));
      return response.data;
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const getConnectedCalendarsService = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axios.get(`${CAL_API_URL}/calendars`, {
        headers: {
          Authorization: `Bearer ${getState().calCom.accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response && response.data) {
        const { data } = response.data;
        dispatch(setConnectedCalendarsSuccess({ calendars: data.connectedCalendars || [] }));
      }
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};
