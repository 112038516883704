import { useEffect } from "react";
import { CalProvider } from "@calcom/atoms";
import { useIntercom } from "react-use-intercom";

import ThemeCustomization from "./themes";
import Routes from "./routes";

import { JWTProvider } from "./contexts/JWTContext";
import ScrollTop from "./utils/ScrollTop";

import { API_URL, CAL_API_URL, CAL_OAUTH_CLIENT_ID, GOOGLE_PLACES_API_KEY } from "./utils/environment";

import { dispatch, useSelector } from "./store";
import { getCalUserService } from "./services/calcom";

const App = () => {
  const { boot, shutdown } = useIntercom();

  const { user } = useSelector((state) => state.user);
  const { calUser } = useSelector((state) => state.calCom);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (user) {
      boot({
        user_id: user._id,
        name: user.fullName,
        email: user.email,
        created_at: user.createdAt,
      });

      if (user) {
        dispatch(getCalUserService(user._id));
      }
    } else {
      boot();
    }

    return () => {
      shutdown();
    };
  }, [user]);

  return (
    <CalProvider
      accessToken={calUser?.access_token}
      clientId={CAL_OAUTH_CLIENT_ID ?? ""}
      options={{ apiUrl: CAL_API_URL ?? "", refreshUrl: `${API_URL}/api/v1/cal/refreshtoken/${user?._id}` }}
    >
      <ThemeCustomization>
        <ScrollTop>
          <JWTProvider>
            <Routes />
          </JWTProvider>
        </ScrollTop>
      </ThemeCustomization>
    </CalProvider>
  );
};

export default App;
