import { useEffect, useState } from "react";

import { useSelector } from "../store";

export const useUserRole = () => {
  const { user } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);

  const [userRole, setUserRole] = useState("user");

  useEffect(() => {
    if (user && user.roles.length && company) {
      const role = user.roles.find((role) => role.companyId === company._id)?.role;
      setUserRole(role);
    }

    if (user?.isDemo) {
      setUserRole("demo");
    }
  }, [user, company]);

  return userRole;
};
