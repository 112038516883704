import { lazy } from "react";

import Loadable from "../components/Loadable";

import MainLayout from "../layouts/MainLayout";
import AuthGuard from "../layouts/RouteGuard/AuthGuard";

const CompanyPage = Loadable(lazy(() => import("../pages/views/Company")));
const MyTeamPage = Loadable(lazy(() => import("../pages/views/Company/MyTeam")));

const JobsPage = Loadable(lazy(() => import("../pages/views/Jobs")));
const JobDetailsPage = Loadable(lazy(() => import("../pages/views/Jobs/JobDetails")));
const JobFormPage = Loadable(lazy(() => import("../pages/views/Jobs/JobFom")));

const MainRoutes = {
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/company/:id",
      element: <CompanyPage />,
    },
    {
      path: "/company/:id/create",
      element: <CompanyPage />,
    },
    {
      path: "/company/:id/my-team",
      element: <MyTeamPage />,
    },
    {
      path: "/jobs/:id",
      element: <JobsPage />,
    },
    {
      path: "/jobs/:id/job/:jid",
      element: <JobDetailsPage />,
    },
    {
      path: "/jobs/:id/job/:cid/create",
      element: <JobFormPage />,
    },
    {
      path: "/jobs/:id/job/:cid/edit/:jid",
      element: <JobFormPage />,
    },
  ],
};

export default MainRoutes;
