// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiPopper-root {
}

.MuiPopper-root .MuiTooltip-tooltip {
  border-radius: 8px;
  border: 1px solid #e2e4e9;
  background: #f6f8fa;
  box-shadow: 0px 16px 32px -12px rgba(88, 92, 95, 0.1);
  margin-bottom: 0 !important;
  padding: 8px;

  color: #0a0d14;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  min-width: 300px;
  min-height: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/tooltip.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,qDAAqD;EACrD,2BAA2B;EAC3B,YAAY;;EAEZ,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;;EAEjB,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".MuiPopper-root {\n}\n\n.MuiPopper-root .MuiTooltip-tooltip {\n  border-radius: 8px;\n  border: 1px solid #e2e4e9;\n  background: #f6f8fa;\n  box-shadow: 0px 16px 32px -12px rgba(88, 92, 95, 0.1);\n  margin-bottom: 0 !important;\n  padding: 8px;\n\n  color: #0a0d14;\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 16px;\n\n  min-width: 300px;\n  min-height: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
