import React, { createContext, useEffect } from "react";

import { useDispatch, useSelector } from "../store";
import { logoutSuccess } from "../store/slices/user";
import { getUserService } from "../services/authentication";
import { cookieStorage } from "../utils/cookie";

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const dispatch = useDispatch();

  const { isLoggedIn, loading } = useSelector((state) => state.user);

  useEffect(() => {
    const init = async () => {
      try {
        const userId = cookieStorage.getItem("uuid");
        if (userId) {
          dispatch(getUserService(userId));
          cookieStorage.setItem("uuid", userId);
        } else {
          cookieStorage.clear();
          dispatch(logoutSuccess());
        }
      } catch (err) {
        cookieStorage.clear();
        dispatch(logoutSuccess());
      }
    };

    if (!isLoggedIn) {
      init();
    }
  }, [dispatch, isLoggedIn]);

  return <JWTContext.Provider value={{ isLoggedIn, loading }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
