import { useEffect, useState } from "react";
import { Box, Divider, List, ListItemButton, ListItemText, Menu, styled, Typography, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { RiAddFill, RiArrowDownSLine } from "react-icons/ri";

import { dispatch, useSelector } from "../../../../store";
import { companyChangeSuccess } from "../../../../store/slices/company";

import NavItem from "./NavItem";
import NavGroup from "./NavGroup";
import Button from "../../../../components/ui/Button";

import { menuItems } from "../../menu-item";
import { overflowStyle } from "../../../../constant/style/Overflow";
import { cookieStorage } from "../../../../utils/cookie";

import { useUserRole } from "../../../../hooks/useUserRole";

import { openErrorSnackbar } from "../../../../helper/snackbar";
import { SnackBarVariant } from "../../../../enums/snackVariant";

export const StyledHead = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1.2),
  textTransform: "uppercase",
  color: theme.palette.placeHolder,
}));

const DrawerContent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const param = useParams();

  const [companyLogo, setCompanyLogo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companies, setCompanyList] = useState([]);

  const { company, companyList } = useSelector((state) => state.company);
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const [anchorEl, setAnchorEl] = useState(null);
  const isCompanyMenuOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const navGroups = menuItems(param).map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} menu={item} level={1} parentId={item.id} />;
      default:
        return <NavItem key={item.id} item={item} />;
    }
  });

  useEffect(() => {
    if (company) {
      setCompanyLogo(company.company_logo);
      setCompanyName(company.company_name);
      setCompanyList(companyList);
    }
  }, [company]);

  const role = useUserRole();

  return (
    <Box sx={{ pt: 2, padding: theme.spacing(5, 0), flex: 1 }}>
      {companies.length !== 0 && (
        <ListItemButton
          onClick={handleClick}
          aria-controls={"company-menu"}
          sx={{
            zIndex: 1201,
            p: theme.spacing(2, 3),
            mb: 4,
            gap: theme.spacing(2),
            borderRadius: theme.spacing(2),
            "&:hover": {
              bgcolor: theme.palette.background.paper,
            },
          }}
        >
          {companyLogo && <img src={companyLogo} alt="logo" style={{ width: "24px", height: "24px", borderRadius: "4px" }} />}
          <ListItemText
            sx={{
              width: "95%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              "-webkit-line-clamp": "1",
              "-webkit-box-orient": "vertical",
            }}
            primary={<Typography variant="labelXS">{companyName}</Typography>}
          />
          <RiArrowDownSLine size="20px" />
        </ListItemButton>
      )}
      <Menu
        id="company-menu"
        open={isCompanyMenuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="mui-menu"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Box sx={{ ...overflowStyle, maxHeight: "110px", overflowY: "auto" }}>
          {companies.map((item) => {
            return (
              <ListItemButton
                key={item._id}
                sx={{
                  zIndex: 1201,
                  py: 0,
                  px: 2,
                  gap: theme.spacing(2),
                  borderRadius: theme.spacing(2),
                  "&:hover": {
                    bgcolor: theme.palette.background.paper,
                  },
                  minWidth: "225px",
                }}
                onClick={() => {
                  handleClose();
                  cookieStorage.setItem("company_id", item._id);
                  dispatch(companyChangeSuccess({ company: item }));
                }}
              >
                {item.company_logo && <img src={item.company_logo} alt="logo" style={{ width: "32px", height: "32px", borderRadius: "4px" }} />}
                <ListItemText
                  sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1), maxWidth: "170px" }}
                  primary={
                    <Typography
                      variant="labelSM"
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        "-webkit-line-clamp": "1",
                        "-webkit-box-orient": "vertical",
                      }}
                    >
                      {item.company_name}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="paragraphXS" sx={{ color: theme.palette.font.soft }}>
                      Beta Tester, 8 members
                    </Typography>
                  }
                />
              </ListItemButton>
            );
          })}
        </Box>
        <Divider sx={{ my: 2 }} />
        <Button
          onClick={() => {
            if (role === "demo") {
              handleClose();
              openErrorSnackbar({ message: "You don't have permission to add company", variant: SnackBarVariant.Error });
            } else {
              handleClose();
              navigate(`/company/${param.id}/create`);
            }
          }}
          fullWidth
          Icon={RiAddFill}
          title="Add Company"
          variant="neutral"
          state="Ghost"
          sx={{ justifyContent: "flex-start" }}
        />
      </Menu>

      <StyledHead variant="h6">Main</StyledHead>
      <List sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}>{navGroups}</List>
    </Box>
  );
};

export default DrawerContent;
