import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import ThemeCustomization from "./themes";

import { JWTProvider } from "./contexts/JWTContext";

import Routes from "./routes";

import ScrollTop from "./utils/ScrollTop";
import { GOOGLE_PLACES_API_KEY } from "./utils/environment";

import { useSelector } from "./store";

const App = () => {
  const { boot, shutdown } = useIntercom();

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (user) {
      boot({
        user_id: user._id,
        name: user.fullName,
        email: user.email,
        created_at: user.createdAt,
      });
    } else {
      boot();
    }

    return () => {
      shutdown();
    };
  }, [user]);

  return (
    <ThemeCustomization>
      <ScrollTop>
        <JWTProvider>
          <Routes />
        </JWTProvider>
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default App;
