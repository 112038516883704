import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  job: null,
  jobList: [],
  activeJobList: [],
  unpublishedJobList: [],
  draftJobList: [],
  hasMorePage: false,
  currentPage: 1,
  totalJobs: 0,
  mainKanbanStatus: ["Rejected", "Applied", "Top Candidates", "AI Phone Interview Pending", "AI Phone Interview Completed"],
  kanbanStatus: ["Rejected", "Applied", "Top Candidates", "AI Phone Interview Pending", "AI Phone Interview Completed"],
  kanban: {
    Rejected: {
      orders: [],
      items: [],
      name: "Rejected",
    },
    Applied: {
      orders: [],
      items: [],
      name: "Applied",
    },
    "Top Candidates": {
      orders: [],
      items: [],
      name: "Top Candidates",
    },
    "AI Phone Interview Pending": {
      orders: [],
      items: [],
      name: "AI Phone Interview Pending",
    },
    "AI Phone Interview Completed": {
      orders: [],
      items: [],
      name: "AI Phone Interview Completed",
    },
  },
  candidateDetail: null,
};

const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    jobDetailsSuccess(state, action) {
      const { job } = action.payload;
      state.job = job;
      state.loading = false;
    },
    jobListSuccess(state, action) {
      const { data, status } = action.payload;
      state.jobList = [...state.jobList, ...data.jobs];

      if (status === "active") {
        state.activeJobList = [...data.jobs];
      }

      if (status === "unpublished") {
        state.unpublishedJobList = [...data.jobs];
      }

      if (status === "draft") {
        state.draftJobList = [...data.jobs];
      }

      state.hasMorePage = data.currentPage < data.totalPages;
      state.currentPage = data.currentPage;
      state.totalJobs = data.totalJobs;
      state.loading = false;
    },
    jobCreateSuccess(state, action) {
      const { job } = action.payload;
      state.jobList = [...state.jobList, job];
      state.job = job;
      state.totalJobs += 1;
      state.loading = false;
    },
    jobUpdateSuccess(state, action) {
      const { job } = action.payload;

      const oldJob = state.jobList.find((j) => j._id === job._id);

      const updatedJobList = state.jobList.map((j) => (j._id === job._id ? job : j));
      state.jobList = updatedJobList;

      if (oldJob.status === "active") {
        state.activeJobList = state.activeJobList.filter((j) => j._id !== job._id);
      } else if (oldJob.status === "unpublished") {
        state.unpublishedJobList = state.unpublishedJobList.filter((j) => j._id !== job._id);
      } else if (oldJob.status === "draft") {
        state.draftJobList = state.draftJobList.filter((j) => j._id !== job._id);
      }

      if (job.status === "active") {
        state.activeJobList = [...state.activeJobList, job];
      } else if (job.status === "unpublished") {
        state.unpublishedJobList = [...state.unpublishedJobList, job];
      } else if (job.status === "draft") {
        state.draftJobList = [...state.draftJobList, job];
      }

      state.job = job;
      state.loading = false;
    },
    jobDeleteSuccess(state, action) {
      const { job } = action.payload;
      if (job.status === "active") {
        state.activeJobList = state.activeJobList.filter((item) => item._id !== job._id);
      } else if (job.status === "unpublished") {
        state.unpublishedJobList = state.unpublishedJobList.filter((item) => item._id !== job._id);
      } else if (job.status === "draft") {
        state.draftJobList = state.draftJobList.filter((item) => item._id !== job._id);
      }
      state.loading = false;
    },
    jobOrderIdSuccess(state, action) {
      const { orderList } = action.payload;
      state.kanbanStatus = state.mainKanbanStatus;
      Object.entries(orderList).map((item) => {
        if (!state.kanbanStatus.includes(item[0])) {
          state.kanbanStatus = [...state.kanbanStatus, item[0]];
          state.kanban = {
            ...state.kanban,
            [item[0]]: { orders: [], items: [], name: item[0] },
          };
        }
        state.kanban[item[0]].orders = item[1];
      });
      state.loading = false;
    },
    jobUserListSuccess(state, action) {
      const { userList } = action.payload;

      Object.keys(state.kanban).forEach((key) => {
        state.kanban[key].items = [];
      });

      userList.forEach((user) => {
        const column = state.kanban[user.status];
        if (column) {
          const index = column.orders.indexOf(user._id);
          if (index !== -1) {
            column.items[index] = user;
          } else {
            column.items.push(user);
          }
        }
      });
      state.loading = false;
    },
    updateKanbanSuccess(state, action) {
      const { kanban } = action.payload;
      state.kanban = kanban;
      state.loading = false;
    },
    candidateDetailSuccess(state, action) {
      const { candidateDetail } = action.payload;
      state.candidateDetail = candidateDetail;
      state.loading = false;
    },
    addCustomStatusSuccess(state, action) {
      const { status } = action.payload;
      state.kanbanStatus = [...state.kanbanStatus, status];
      state.kanban = {
        ...state.kanban,
        [status]: { orders: [], items: [], name: status },
      };
      state.loading = false;
    },
    editCustomStatusSuccess(state, action) {
      const { status } = action.payload;
      const { oldStatus, newStatus } = status;
      if (state.kanban[oldStatus]) {
        const oldStatusDetails = state.kanban[oldStatus];
        oldStatusDetails.name = newStatus;
        state.kanban[newStatus] = oldStatusDetails;
        delete state.kanban[oldStatus];
      }
      state.kanbanStatus = state.kanbanStatus.map((item) => (item === oldStatus ? newStatus : item));
      state.loading = false;
    },
    deleteCustomStatusSuccess(state, action) {
      const { status } = action.payload;
      state.kanbanStatus = state.kanbanStatus.filter((item) => item !== status);
      state.loading = false;
    },
    loadingSuccess(state, action) {
      const { loading } = action.payload;
      state.loading = loading;
    },
  },
});

export const {
  jobListSuccess,
  jobDetailsSuccess,
  jobCreateSuccess,
  jobUpdateSuccess,
  jobDeleteSuccess,
  jobOrderIdSuccess,
  jobUserListSuccess,
  updateKanbanSuccess,
  candidateDetailSuccess,
  addCustomStatusSuccess,
  editCustomStatusSuccess,
  deleteCustomStatusSuccess,
  loadingSuccess,
} = jobSlice.actions;

export default jobSlice.reducer;
