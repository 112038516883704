import React, { useMemo } from "react";

import { Alert, Box, Fade, Grow, IconButton, Slide, styled, Typography } from "@mui/material";
import MuiSnackbar from "@mui/material/Snackbar";

import { RiCheckboxCircleFill, RiCloseCircleFill, RiCloseLine } from "react-icons/ri";
import { MdError } from "react-icons/md";

import { useDispatch, useSelector } from "../store";
import { closeSnackbar } from "../store/slices/snackbar";

import { SnackBarVariant } from "../enums/snackVariant";

const getVariantStyles = (variant) => {
  switch (variant) {
    case SnackBarVariant.Default:
      return {
        padding: "8px 32px",
        borderRadius: "36px",
        alignItems: "center",
        background: "#303E8E",
      };
    case SnackBarVariant.Error:
      return {
        padding: "8px 32px",
        borderRadius: "36px",
        alignItems: "center",
        background: "#DF1C41",
      };
    case SnackBarVariant.ErrorNotification:
      return {
        padding: "14px",
        borderRadius: "12px",
        background: "#DF1C41",
        maxWidth: "390px",
      };
  }
};

const getAlignStyles = (variant) => {
  switch (variant) {
    case SnackBarVariant.Default:
    case SnackBarVariant.Error:
      return "center";
    case SnackBarVariant.ErrorNotification:
      return "flex-start";
  }
};

const StyledMuiSnackbar = styled(MuiSnackbar)(({ variant }) => ({
  "& .MuiPaper-root.MuiAlert-root": {
    ...getVariantStyles(variant),
  },

  "& .MuiAlert-message": {
    display: "flex",
    alignItems: getAlignStyles(variant),
    gap: "24px",
  },
}));

const StyledHeader = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
}));

const StyledActions = styled(Box)(() => ({
  "& button": {
    color: "#fff",
  },
}));

function TransitionSlideLeft(props) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}

const animation = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade,
};

const Snackbar = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackbar);

  const { anchorOrigin, close, title, message, open, transition, variant } = snackbar;

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(closeSnackbar());
  };

  const icon = useMemo(() => {
    switch (variant) {
      case SnackBarVariant.Default:
        return <RiCheckboxCircleFill size={20} color="#38C793" />;
      case SnackBarVariant.Error:
        return <RiCloseCircleFill size={20} color="#fff" />;
      case SnackBarVariant.ErrorNotification:
        return <MdError size={20} color="#fff" />;
    }
  }, [variant]);

  return (
    <>
      <StyledMuiSnackbar
        TransitionComponent={animation[transition]}
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        variant={variant}
      >
        <Alert icon={icon}>
          <StyledHeader>
            {title && <Typography variant="h6">{title}</Typography>}
            {message && <Typography variant="subtitle1">{message}</Typography>}
          </StyledHeader>
          <StyledActions>
            {close !== false && (
              <IconButton size="small" onClick={handleClose}>
                <RiCloseLine />
              </IconButton>
            )}
          </StyledActions>
        </Alert>
      </StyledMuiSnackbar>
    </>
  );
};

export default Snackbar;
