import React, { lazy } from "react";

import Loadable from "../components/Loadable";

import WelcomeLayout from "../layouts/WelcomeLayout";
import AuthGuard from "../layouts/RouteGuard/AuthGuard";

const WelcomePage = Loadable(lazy(() => import("../pages/authentication/Welcome")));
const OnboardingPage = Loadable(lazy(() => import("../pages/authentication/Onboarding")));

const WelcomeRoutes = {
  element: (
    <AuthGuard>
      <WelcomeLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/welcome/:id",
      element: <WelcomePage />,
    },
    {
      path: "/onboarding/:id",
      element: <OnboardingPage />,
    },
  ],
};

export default WelcomeRoutes;

// id = user id
