import React from "react";

import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import { Logo, WelcomeBgVector } from "../../utils/images";

const WelcomeLayout = () => {
  return (
    <Box sx={{ position: "relative", width: "100%", height: "100%", minHeight: "100vh", padding: "24px 44px" }}>
      <img src={Logo} alt="" style={{ position: "absolute", top: "24px", left: "44px", width: "40px", height: "40px" }} />
      <img src={WelcomeBgVector} alt="" style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }} />
      <Box sx={{ zIndex: 2, position: "relative" }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default WelcomeLayout;
