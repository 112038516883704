import { SnackBarVariant } from "../enums/snackVariant";
import { dispatch } from "../store";
import { openSnackbar } from "../store/slices/snackbar";

export const openErrorSnackbar = ({
  title = "",
  message = "",
  variant = SnackBarVariant.Default,
  anchorOrigin = { vertical: "top", horizontal: "center" },
  transition = "SlideDown",
}) => {
  dispatch(
    openSnackbar({
      open: true,
      title: title,
      message: message,
      anchorOrigin: anchorOrigin,
      transition: transition,
      variant: variant,
      close: true,
    })
  );
};
