const Typography = () => ({
  htmlFontSize: 16,
  fontFamily: "'Inter', sans-serif",
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontWeight: 500,
    fontSize: "56px",
    lineHeight: "64px",
    letterSpacing: "-0.56px",
  },
  h2: {
    fontWeight: 500,
    fontSize: "48px",
    lineHeight: "56px",
    letterSpacing: "-0.48px",
  },
  h3: {
    fontWeight: 500,
    fontSize: "40px",
    lineHeight: "48px",
    letterSpacing: "-0.4px",
  },
  h4: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "28px",
  },
  h5: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "32px",
  },
  h6: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
  caption: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
  },
  body1: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  body2: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
  },
  subtitle1: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  subtitle2: {
    fontSize: "12px",
    lineHeight: "16px",
  },
  overline: {
    lineHeight: 1.66,
  },
  button: {
    textTransform: "capitalize",
  },
  // here
  labelXXL: {
    fontWeight: 500,
    letterSpacing: "-0.36px",
    lineHeight: "36px",
    fontSize: "28px",
  },
  labelXL: {
    fontWeight: 500,
    letterSpacing: "-0.36px",
    lineHeight: "32px",
    fontSize: "24px",
  },
  labelLG: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "-0.27px",
  },
  labelMD: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.176px",
  },
  labelSM: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "-0.084px",
  },
  labelXS: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
  },
  paragraphXL: {
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.36px",
  },
  paragraphLG: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "-0.27px",
  },
  paragraphMD: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.176px",
  },
  paragraphSM: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "-0.084px",
  },
  paragraphXS: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
  },
  subHeadingMD: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.96px",
    textTransform: "uppercase",
  },
  subHeadingSM: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.84px",
    textTransform: "uppercase",
  },
  subHeadingXS: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.48px",
    textTransform: "uppercase",
  },
  subHeadingXXS: {
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "12px",
    letterSpacing: "0.22px",
    textTransform: "uppercase",
  },
});

export default Typography;
