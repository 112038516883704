import React from "react";
import ReactDOM from "react-dom/client";

import "./assets/styles/third-party.css";
import "./assets/styles/dialog.css";
import "./assets/styles/tooltip.css";

import "@calcom/atoms/globals.min.css";
import "react-grid-layout/css/styles.css";

import "./index.css";

import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { IntercomProvider } from "react-use-intercom";

import App from "./App";

import Snackbar from "./components/Snackbar";

import { store } from "./store";

import { INTERCOM_KEY } from "./utils/environment";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <IntercomProvider appId={INTERCOM_KEY}>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <App />
        <Snackbar />
      </BrowserRouter>
    </ReduxProvider>
  </IntercomProvider>
);
