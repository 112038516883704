import React from "react";

import { Box, styled } from "@mui/system";

const getVariantStyles = (variant, theme) => {
  switch (variant) {
    case "Blue":
      return {
        backgroundColor: theme.palette.blue.lighter,
        color: theme.palette.blue.base,
      };
    case "Green":
      return {
        backgroundColor: theme.palette.green.lighter,
        color: theme.palette.green.base,
      };
    case "Red":
      return {
        backgroundColor: theme.palette.red.lighter,
        color: theme.palette.red.base,
      };
  }
};

const StyledStatusModal = styled(Box)(({ theme, variant }) => ({
  width: "32px",
  height: "32px",
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),

  ...getVariantStyles(variant, theme),
}));

const StatusModal = ({ variant, icon, className = "" }) => {
  return (
    <StyledStatusModal className={className} variant={variant}>
      {icon}
    </StyledStatusModal>
  );
};

export default StatusModal;
