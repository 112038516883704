import { RiBriefcaseLine, RiBuilding2Line, RiCalendar2Line } from "react-icons/ri";

const icons = {
  RiBriefcaseLine,
  RiBuilding2Line,
  RiCalendar2Line,
};

export const menuItems = (param) => [
  {
    id: "jobs",
    title: "Jobs",
    url: `jobs/${param.id}`,
    icon: icons.RiBriefcaseLine,
    type: "item",
  },
  {
    id: "company",
    title: "Company",
    icon: icons.RiBuilding2Line,
    type: "group",
    children: [
      {
        id: "company",
        title: "Company Profile",
        type: "item",
        url: `company/${param.id}`,
      },
      {
        id: "my-team",
        title: "My Team",
        type: "item",
        url: `company/${param.id}/my-team`,
      },
    ],
  },
];
