import React from "react";
import { Box, Typography, styled } from "@mui/material";
import { MagicFillIcon } from "../../utils/images";
import { Link } from "react-router-dom";

const StyledBanner = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  backgroundColor: "#11173B",
  color: theme.palette.common.white,
  padding: "12px 0",
  transition: "all 0.3s ease-in-out",
}));

const BannerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",

  a: {
    color: "#C0F6F6",
    textDecoration: "underline",
  },
}));

const DemoBanner = () => {
  return (
    <StyledBanner>
      <BannerContainer>
        <img src={MagicFillIcon} alt="magic" />
        <Typography variant="paragraphXS">
          You are using a demo account to explore the platform. To create your own company, click the{" "}
          <Link to="https://cal.com/huseyin-ss/auma-intro" target="_blank">
            Book a Call
          </Link>{" "}
          for assistance with your first account setup!
        </Typography>
      </BannerContainer>
    </StyledBanner>
  );
};

export default DemoBanner;
