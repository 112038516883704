import { SnackBarVariant } from "../enums/snackVariant";
import { openErrorSnackbar } from "../helper/snackbar";

import { dispatch } from "../store";
import {
  companyDetailsSuccess,
  companyTeamsSuccess,
  createCompanySuccess,
  editCompanySuccess,
  getCompanyByDomainSuccess,
  loadingSuccess,
  removeTeamMemberSuccess,
} from "../store/slices/company";

import axiosServices from "../utils/axios";

import { cookieStorage } from "../utils/cookie";

export const getCompanyDetailService = (id) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.get(`/company/${id}`, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(companyDetailsSuccess({ company: response.data.data }));
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const getCompanyInfoByDomainService = (body) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.post("/company/create-company-auto", body, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(getCompanyByDomainSuccess({ company: response.data.data }));
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const createCompanyService = (id, body) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.post(`/company/${id}`, body, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      const company = response.data.data;
      dispatch(createCompanySuccess({ company: company }));
      return company;
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const editCompanyService = (id, body, isAutoJoin = false) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.patch(`/company/${id}`, body, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      if (!isAutoJoin) {
        dispatch(editCompanySuccess({ company: response.data.data }));
      }
      dispatch(loadingSuccess({ loading: false }));
      return response.data.data;
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const getCompanyTeamsService = (id) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.get(`/company/get-team-list/${id}`, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(companyTeamsSuccess({ teams: response.data.data }));
    } catch (error) {
      dispatch(companyTeamsSuccess({ teams: { invitees: [] } }));
    }
  };
};

export const inviteTeamMembersService = (id, body) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.post(`/company/create-invitation/${id}`, body, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(loadingSuccess({ loading: false }));
      response.data.data.errorList.map((item) => {
        openErrorSnackbar({ message: item.msg, variant: SnackBarVariant.Error });
      });
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const updateTeamMemberService = (companyId, inviteId, body) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      await axiosServices.patch(`/company/edit-team-member/${inviteId}/${companyId}`, body, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(loadingSuccess({ loading: false }));
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const removeTeamMemberService = (companyId, inviteId) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      await axiosServices.delete(`/company/delete-team-member/${inviteId}/${companyId}`, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(removeTeamMemberSuccess({ id: inviteId }));
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const resendTeamMemberService = (companyId, body) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      await axiosServices.post(`/company/resend-invitation/${companyId}`, body, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(loadingSuccess({ loading: false }));
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};
