import { createSlice } from "@reduxjs/toolkit";
import { cookieStorage } from "../../utils/cookie";

const initialState = {
  loading: true,
  isCompanyCreated: false,
  company: null,
  companyList: null,
  isFormChanged: false,
  teams: [],
  domainCompany: null,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    companyChangeSuccess(state, action) {
      const { company } = action.payload;
      state.company = company;
      state.loading = false;
    },
    companyDetailsSuccess(state, action) {
      const companyId = cookieStorage.getItem("company_id");
      const { company } = action.payload;
      state.company = company.length > 0 ? (companyId ? company.find((c) => c._id === companyId) || company[0] : company[0]) : null;
      state.isCompanyCreated = company.length > 0;
      state.companyList = company;
      state.loading = false;
    },
    createCompanySuccess(state, action) {
      const { company } = action.payload;
      state.company = { ...company };
      state.isCompanyCreated = true;
      state.companyList = state.companyList ? [...state.companyList, company] : [company];
      state.loading = false;
    },
    editCompanySuccess(state, action) {
      const { company } = action.payload;
      const index = state.companyList.findIndex((c) => c._id === company._id);
      if (index !== -1) {
        state.companyList[index] = company;
      }
      state.company = company;
      state.loading = false;
    },
    formChangedSuccess(state, action) {
      const { isChanged } = action.payload;
      state.isFormChanged = isChanged;
    },
    companyTeamsSuccess(state, action) {
      const { teams } = action.payload;
      state.teams = teams.invitees;
      state.loading = false;
    },
    removeTeamMemberSuccess(state, action) {
      const { id } = action.payload;
      state.teams = state.teams.filter((team) => team._id !== id);
      state.loading = false;
    },
    getCompanyByDomainSuccess(state, action) {
      const { company } = action.payload;
      state.domainCompany = company;
      state.company = null;
      state.loading = false;
    },
    loadingSuccess(state, action) {
      const { loading } = action.payload;
      state.loading = loading;
    },
  },
});

export const {
  companyTeamsSuccess,
  removeTeamMemberSuccess,
  companyChangeSuccess,
  companyDetailsSuccess,
  createCompanySuccess,
  editCompanySuccess,
  formChangedSuccess,
  getCompanyByDomainSuccess,
  loadingSuccess,
} = companySlice.actions;

export default companySlice.reducer;
