import { lazy } from "react";

import Loadable from "../components/loader/Loadable";

const VoiceCallPage = Loadable(lazy(() => import("../pages/public/VoiceCall")));
const ApplicationPage = Loadable(lazy(() => import("../pages/public/Application")));
const CalBookerPage = Loadable(lazy(() => import("../pages/public/CalBooker")));

const PublicRoutes = {
  children: [
    {
      path: "/voice-call/:id",
      element: <VoiceCallPage />,
    },
    {
      path: "/job/:id",
      element: <ApplicationPage />,
    },
    {
      path: "/auma-booker",
      element: <CalBookerPage />,
    },
  ],
};

export default PublicRoutes;
